import {
  AfterViewInit,
  Component,
  Input,
  ViewChild,
  HostListener,
  EventEmitter,
  Output,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DatePipe } from "@angular/common";
import { GlobalVariable } from "app/global/global";
import { environment } from "environments/environment.prod";
import { ActivatedRoute, Router } from "@angular/router";
import { ShowImagenComponent } from "app/shared/show-imagen/show-imagen.component";
import { MatDialog } from "@angular/material/dialog";
import { saveAs } from "file-saver";
import { Medico } from "app/models/medico.interface";
import { EstudioMedicoPacienteService } from "../../../services/estudio-medico-paciente.service";

@Component({
  selector: "app-estudios-medicos",
  templateUrl: "./estudios-medicos.component.html",
  styleUrls: ["./estudios-medicos.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class EstudiosMedicosComponent implements AfterViewInit {
  //
  @Input("cols") cols: any[] = [];
  isOpen = false;
  url: string = GlobalVariable.BASE_API_URL;
  urlXls: string = GlobalVariable.BASE_API_URL + "storage/images/paciente/";
  dataSource: any;
  sortedData = [];
  @ViewChild(MatSort) sort: MatSort;
  idPaciente: string;
  medico: Medico;
  imageData: any;
  paciente: any;
  estudiosMedicosPaciente: any;
  tipoestudios = environment.BASE_TYPEOFESTUDIOS;
  columnsFiles = ["fecha", "estudio", "medico", "centromedico", "acciones"];
  columnsFile = ["fecha", "archivo", "acciones"];
  historialMedico = false;
  @Input() set response(value: any) {
    if (value) {
      this.listarEstudiosMedicos(value);
    }
  }

  @Input() set responseMedico(value: any) {
    if (value) {
      this.medico = value;
    }
  }

  @Input() formData = null;
  @Output() listar: EventEmitter<boolean> = new EventEmitter<boolean>();

  //
  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private actRoute: ActivatedRoute,
    private router: Router,
    private estudioMedicoPacienteService: EstudioMedicoPacienteService
  ) {
    this.idPaciente = this.actRoute.snapshot.paramMap.get("id");
    if (window.innerWidth > 900) {
      this.historialMedico = false;
    } else {
      this.historialMedico = true;
    }
  }

  //
  ngAfterViewInit() {}

  //
  @HostListener("window:resize", ["$event"]) onResize(event) {
    if (event.target.innerWidth > 900) {
      this.historialMedico = false;
    } else if (event.target.innerWidth <= 900) {
      this.historialMedico = true;
    }
  }

  //
  listarEstudiosMedicos(data: any) {
    this.paciente = data.paciente;
    this.estudiosMedicosPaciente = data.estudiosMedicosPaciente;
    this.estudiosMedicosPaciente.sort((a, b) => {
      return new Date(b.fecha).getTime() - new Date(a.fecha).getTime();
    });
    this.estudiosMedicosPaciente.forEach((element) => {
      let date = new Date(element.fecha);
      element.fecha = this.datePipe.transform(date, "dd/MM/yyyy");
      element.imagenCM = this.url + "storage/images/centro-medico/" + element.imagenCM;
    });
    this.dataSource = new MatTableDataSource(this.estudiosMedicosPaciente);
    this.dataSource.sort = this.sort;
  }

  //
  async mostrarImagen(imagen: any) {
    const dialogRef = this.dialog.open(ShowImagenComponent, {
      width: "95%",
      height: "95%",
      data: imagen,
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  //
  descargarImage(imagen: any) {
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls + id + "/" + imagen.id + "/" + imagen.imagen.nombre).then(
      function (response) {
        response.blob().then(function (blob) {
          let file = new File([blob], imagen.imagen.nombre, {
            type: "application/jpeg",
          });
          saveAs(file);
        });
      }
    );
  }

  //
  descargarPdf(imagen: any) {
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls + id + "/" + imagen.id + "/" + imagen.imagen.nombre).then(
      function (response) {
        response.blob().then(function (blob) {
          let file = new File([blob], imagen.imagen.nombre, {
            type: "application/pdf",
          });
          saveAs(file);
        });
      }
    );
  }

  //
  descargarExcel(imagen: any) {
    let id = imagen.imagen.idPaciente;
    fetch(this.urlXls + id + "/" + imagen.id + "/" + imagen.imagen.nombre).then(
      function (response) {
        response.blob().then(function (blob) {
          let file = new File([blob], imagen.imagen.nombre, {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(file);
        });
      }
    );
  }

  //
  cargarImagen(event) {
    (event.target as HTMLImageElement).style.display = "flex";
  }

  //
  select(expandedElement, element) {
    //this.router.navigate(['/seccion-medico/estudios-medicos/detalle',element.id]);
  }

  public editarEstudio(ruta: string[]) {
    this.router.navigate(ruta);
  }

  descargarPDFEstudioMedico(estudioMedicoPaciente) {
    const em = {
      ...estudioMedicoPaciente,
      nombreP: this.paciente.nombreApellido,
    };

    const date = new Date(Date.parse(em.fecha));
    const formatedDate = this.datePipe.transform(date, "dd/MM/yyyy");

    em.fecha = formatedDate;

    this.estudioMedicoPacienteService.descargarPDFEstudioMedico(em);
  }
}
